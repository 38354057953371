<template>
  <div>
    <dialog-form-list
        width="1000px"
        :config="config"
        :formData="formData"
        @cancel="cancel"
        @confirm="confirm"
        @openDialog="openDialog"
    >
      <template v-slot:companyLogoImgId>
        <div class="upload" @click="clickUpload('companyLogoImgId')">
          <div v-if="!formData.companyLogoImgId" class="upload--no-img" ><i class="el-icon-plus"></i></div>
          <img v-else :src="getSrc(formData.companyLogoImgId)" class="upload--has-img"/>
          <div>{{$t('companyOemManage.suggestedResolution')}}{{aspectRatioMap.companyLogoImgId[productType].width}}×{{aspectRatioMap.companyLogoImgId[productType].height}}</div>
        </div>
      </template>
      <template v-slot:productLogoZhImgId>
        <div class="upload" @click="clickUpload('productLogoZhImgId')">
          <div v-if="!formData.productLogoZhImgId" class="upload--no-img" ><i class="el-icon-plus"></i></div>
          <img v-else :src="getSrc(formData.productLogoZhImgId)" class="upload--has-img"/>
          <div>{{$t('companyOemManage.suggestedResolution')}}{{aspectRatioMap.productLogoZhImgId[productType].width}}×{{aspectRatioMap.productLogoZhImgId[productType].height}}</div>
        </div>
      </template>
      <template v-slot:productLogoEnImgId>
        <div class="upload" @click="clickUpload('productLogoEnImgId')">
          <div v-if="!formData.productLogoEnImgId" class="upload--no-img" ><i class="el-icon-plus"></i></div>
          <img v-else :src="getSrc(formData.productLogoEnImgId)" class="upload--has-img"/>
          <div>{{$t('companyOemManage.suggestedResolution')}}{{aspectRatioMap.productLogoEnImgId[productType].width}}×{{aspectRatioMap.productLogoEnImgId[productType].height}}</div>
        </div>
      </template>
      <template v-slot:productSceneZhWebImgId>
        <div class="upload" @click="clickUpload('productSceneZhWebImgId')">
          <div v-if="!formData.productSceneZhWebImgId" class="upload--no-img" ><i class="el-icon-plus"></i></div>
          <img v-else :src="getSrc(formData.productSceneZhWebImgId)" class="upload--has-img"/>
          <div>{{$t('companyOemManage.suggestedResolution')}}{{aspectRatioMap.productSceneZhWebImgId[productType].width}}×{{aspectRatioMap.productSceneZhWebImgId[productType].height}}</div>
        </div>
      </template>
      <template v-slot:productSceneEnWebImgId>
        <div class="upload" @click="clickUpload('productSceneEnWebImgId')">
          <div v-if="!formData.productSceneEnWebImgId" class="upload--no-img" ><i class="el-icon-plus"></i></div>
          <img v-else :src="getSrc(formData.productSceneEnWebImgId)" class="upload--has-img"/>
          <div>{{$t('companyOemManage.suggestedResolution')}}{{aspectRatioMap.productSceneEnWebImgId[productType].width}}×{{aspectRatioMap.productSceneEnWebImgId[productType].height}}</div>
        </div>
      </template>
      <template v-slot:productSceneZhAndroidImgId>
        <div class="upload" @click="clickUpload('productSceneZhAndroidImgId')">
          <div v-if="!formData.productSceneZhAndroidImgId" class="upload--no-img" ><i class="el-icon-plus"></i></div>
          <img v-else :src="getSrc(formData.productSceneZhAndroidImgId)" class="upload--has-img"/>
          <div>{{$t('companyOemManage.suggestedResolution')}}{{aspectRatioMap.productSceneZhAndroidImgId[productType].width}}×{{aspectRatioMap.productSceneZhAndroidImgId[productType].height}}</div>
        </div>
      </template>
      <template v-slot:productSceneEnAndroidImgId>
        <div class="upload" @click="clickUpload('productSceneEnAndroidImgId')">
          <div v-if="!formData.productSceneEnAndroidImgId" class="upload--no-img" ><i class="el-icon-plus"></i></div>
          <img v-else :src="getSrc(formData.productSceneEnAndroidImgId)" class="upload--has-img"/>
          <div>{{$t('companyOemManage.suggestedResolution')}}{{aspectRatioMap.productSceneEnAndroidImgId[productType].width}}×{{aspectRatioMap.productSceneEnAndroidImgId[productType].height}}</div>
        </div>
      </template>
      <template v-slot:xrWorkVersionConfigs>
        <div class="xrsop-version-configs">
          <div v-for="item in formData.xrWorkVersionConfigs" class="xrsop-version-configs__item">
            <div class="version-name">{{$t('businessEnum.' + item.xrWorkType)}}</div>
            <el-row :gutter="10">
              <el-col :span="11">
                {{$t('companyOemManage.loginBgZhImgId')}}:
                <div class="upload" @click="clickUpload('loginBgZhImgId', item.xrWorkType)">
                  <div v-if="!item.loginBgZhImgId" class="upload--no-img" ><i class="el-icon-plus"></i></div>
                  <img v-else :src="getSrc(item.loginBgZhImgId)" class="upload--has-img"/>
                  <div>{{$t('companyOemManage.suggestedResolution')}}{{aspectRatioMap.loginBgZhImgId[productType].width}}×{{aspectRatioMap.loginBgZhImgId[productType].height}}</div>
                </div>
              </el-col>
              <el-col type="flex" :span="11">
                {{$t('companyOemManage.loginBgEnImgId')}}:
                <div class="upload" @click="clickUpload('loginBgEnImgId', item.xrWorkType)">
                  <div v-if="!item.loginBgEnImgId" class="upload--no-img" ><i class="el-icon-plus"></i></div>
                  <img v-else :src="getSrc(item.loginBgEnImgId)" class="upload--has-img"/>
                  <div>{{$t('companyOemManage.suggestedResolution')}}{{aspectRatioMap.loginBgEnImgId[productType].width}}×{{aspectRatioMap.loginBgEnImgId[productType].height}}</div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="11">
                {{$t('companyOemManage.versionZhName')}}:<el-input v-model="item.versionZhName" maxlength="10" show-word-limit/>
              </el-col>
              <el-col type="flex" :span="11">
                {{$t('companyOemManage.versionEnName')}}:<el-input v-model="item.versionEnName" maxlength="10" show-word-limit/>
              </el-col>
            </el-row>
          </div>
        </div>
      </template>
    </dialog-form-list>
    <!-- 弹出表格 -->
    <dialog-table-list
        width="1200px"
        v-if="showDialogTableList"
        ref="tableList"
        :title="dialogTitle"
        :url="dialogUrl"
        :defaultParams="dialogDefaultParams"
        :columns="dialogColumns"
        :formData="dialogFormData"
        :config="dialogConfig"
        v-model="dialogTableRow"
        @cancel="dialogCancel"
        @confirm="dialogConfirm"
        @search="dialogSearch"
        :multiple="false"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 所属部门 -->
      <template v-slot:accountGroup="{ row }">
        {{ $util.getTreeNames(row.accountGroup, 'name') }}
      </template>
      <!-- 状态 -->
      <template v-slot:enable="{ row }">
        {{ enableObj[row.enable] }}
      </template>
    </dialog-table-list>
    <input type="file" style="display: none;" ref="uploadfile" @change="uploadFile"/>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {showAlert} from "@/utils/element";

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
    productType: {
      type: String,
      default: '',
    }
  },
  data() {
    const _that = this
    return {
      title: this.$t('accountPermissionManage.changeContactVisibleRange'),
      showDialogMultipleTree: false,
      defaultContent: [],
      renewalType: '',
      showRenewal:false,
      cmd: this.$store.state.cmd,
      formData: {
      },
      config: [
        // lang:所属企业
        {
          tag: 'dialog-define',
          label: this.$t('companyOemManage.targetCompany'),
          prop: 'companyUid',
          hidden: false,
          rules: {
            noNull: true,
          },
          span: 12,
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          },
        },
        // lang: 访问地址
        {
          label: this.$t('companyOemManage.webUrl'),
          prop: 'webUrl',
          tagProps: {
            maxlength: 128,
            showWordLimit: true,
          },
        },
        // lang: 产品名称-中文
        {
          label: this.$t('companyOemManage.productZhName'),
          prop: 'productZhName',
          span: 12,
          tagProps: {
            maxlength: 128,
            showWordLimit: true,
          },
        },
        // lang: 产品名称-英文
        {
          label: this.$t('companyOemManage.productEnName'),
          prop: 'productEnName',
          span: 12,
          tagProps: {
            maxlength: 128,
            showWordLimit: true,
          },
        },
        // lang:企业logo
        {
          tag: 'slot-content',
          label: this.$t('companyOemManage.companyLogoImgId'),
          prop: 'companyLogoImgId',
          span: 24,
          hidden: _that.$props.productType !== 'XR_WORK'
        },
        // lang:产品logo-中文
        {
          tag: 'slot-content',
          label: this.$t('companyOemManage.productLogoZhImgId'),
          prop: 'productLogoZhImgId',
          span: 12,
        },
        // lang:产品logo-英文
        {
          tag: 'slot-content',
          label: this.$t('companyOemManage.productLogoEnImgId'),
          prop: 'productLogoEnImgId',
          span: 12,
        },

        // lang:欢迎语-中文
        {
          label: this.$t('companyOemManage.welcomeZhText'),
          prop: 'welcomeZhText',
          span: 12,
          hidden: _that.$props.productType !== 'TMVA',
          tagProps: {
            maxlength: 128,
            showWordLimit: true,
          },
        },
        // lang:欢迎语-英文
        {
          label: this.$t('companyOemManage.welcomeEnText'),
          prop: 'welcomeEnText',
          span: 12,
          hidden: _that.$props.productType !== 'TMVA',
          tagProps: {
            maxlength: 128,
            showWordLimit: true,
          },
        },
        // lang:版权信息-中文
        {
          label: this.$t('companyOemManage.copyrightZhText'),
          prop: 'copyrightZhText',
          span: 12,
          tagProps: {
            maxlength: 128,
            showWordLimit: true,
          },
        },
        // lang:版权信息-英文
        {
          label: this.$t('companyOemManage.copyrightEnText'),
          prop: 'copyrightEnText',
          span: 12,
          tagProps: {
            maxlength: 128,
            showWordLimit: true,
          },
        },
        // 联系我们-展示类型
        {
          tag: 'el-select',
          label: this.$t('companyOemManage.connectUsType'),
          prop: 'connectUsType',
          span: 24,
          tagProps: {
            options: [
              {
                value: 'TEXT',
                label: this.$t('companyOemManage.text')
              },
              {
                value: 'URL',
                label: this.$t('companyOemManage.url')
              }
            ]
          },
          tagEvents: {
            change(val) {
              if(val === 'TEXT') {
                _that.config[12].hidden = false
                _that.config[13].hidden = false
                _that.config[14].hidden = false
                _that.config[15].hidden = false
                _that.config[16].hidden = false
                _that.config[17].hidden = true
              } else if(val === 'URL') {
                _that.config[12].hidden = true
                _that.config[13].hidden = true
                _that.config[14].hidden = true
                _that.config[15].hidden = true
                _that.config[16].hidden = true
                _that.config[17].hidden = false
              }
            }
          }
        },
        // lang:联系我们-企业名称
        {
          label: this.$t('companyOemManage.companyName'),
          prop: 'companyName',
          span: 24,
          tagProps: {
            maxlength: 128,
            showWordLimit: true,
          },
        },
        // lang:联系我们-电话
        {
          label: this.$t('companyOemManage.contactUsTel'),
          prop: 'contactUsTel',
          span: 24,
          rules: {
            // noNull: true ,
            type: 'mobile',
          },
          tagProps: {
            maxLength: 11,
          }
        },
        // lang:联系我们-邮箱
        {
          label: this.$t('companyOemManage.contactUsMail'),
          prop: 'contactUsMail',
          span: 24,
          rules: {
            // noNull: true ,
            type: 'email',
          },
        },
        // lang:联系我们-企业网址
        {
          label: this.$t('companyOemManage.companyWebUrl'),
          prop: 'companyWebUrl',
          span: 24,
          tagProps: {
            maxlength: 128,
            showWordLimit: true,
          },
        },
        // lang:联系我们-企业地址
        {
          label: this.$t('companyOemManage.companyAddress'),
          prop: 'companyAddress',
          span: 24,
          tagProps: {
            maxlength: 128,
            showWordLimit: true,
          },
        },
        // lang:联系我们-跳转地址
        {
          label: this.$t('companyOemManage.contactUsUrl'),
          prop: 'contactUsUrl',
          span: 24,
        },
        // lang:产品标语-中文
        {
          label: this.$t('companyOemManage.productSloganZh'),
          prop: 'productSloganZh',
          span: 12,
          hidden: _that.$props.productType !== 'XR_WORK',
          tagProps: {
            maxlength: 32,
            showWordLimit: true,
          },
        },
        // lang:产品标语-英文
        {
          label: this.$t('companyOemManage.productSloganEn'),
          prop: 'productSloganEn',
          span: 12,
          hidden: _that.$props.productType !== 'XR_WORK',
          tagProps: {
            maxlength: 32,
            showWordLimit: true,
          },
        },
        // lang:产品场景图-中文-web
        {
          tag: 'slot-content',
          label: this.$t('companyOemManage.productSceneZhWebImgId'),
          prop: 'productSceneZhWebImgId',
          span: 12,
          hidden: _that.$props.productType !== 'XR_WORK'
        },
        // lang:产品场景图-英文-web
        {
          tag: 'slot-content',
          label: this.$t('companyOemManage.productSceneEnWebImgId'),
          prop: 'productSceneEnWebImgId',
          span: 12,
          hidden: _that.$props.productType !== 'XR_WORK'
        },
        // lang:产品场景图-中文-Android
        {
          tag: 'slot-content',
          label: this.$t('companyOemManage.productSceneZhAndroidImgId'),
          prop: 'productSceneZhAndroidImgId',
          span: 12,
          hidden: _that.$props.productType !== 'XR_WORK'
        },
        // lang:产品场景图-英文-Android
        {
          tag: 'slot-content',
          label: this.$t('companyOemManage.productSceneEnAndroidImgId'),
          prop: 'productSceneEnAndroidImgId',
          span: 12,
          hidden: _that.$props.productType !== 'XR_WORK'
        },
        // lang:android端帮助信息
        // {
        //   tag: 'tinymce-text',
        //   label: this.$t('companyOemManage.androidHelper'),
        //   prop: 'androidHelper',
        //   span: 24,
        //   tagProps: {
        //     height: 100,
        //   },
        //   hidden: _that.$props.productType !== 'XR_WORK'
        // },

        // lang:android端帮助信息
        {
          tag: 'slot-content',
          label: this.$t('companyOemManage.xrWorkVersionConfigs'),
          prop: 'xrWorkVersionConfigs',
          span: 24,
          hidden: _that.$props.productType !== 'XR_WORK'
        },
      ],

      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: null,
      hasVersionType:[],
      currentUploadProp: null,
      currentUploadXrWorkType: null,
      aspectRatioMap: {
        companyLogoImgId: {
          XR_WORK: {
            width: 223,
            height: 59
          }
        },
        productLogoZhImgId: {
          XR_WORK: {
            width: 127,
            height: 60
          },
          TMVA: {
            width: 201,
            height: 60
          }
        },
        productLogoEnImgId: {
          XR_WORK: {
            width: 127,
            height: 60
          },
          TMVA: {
            width: 201,
            height: 60
          }
        },
        productSceneZhWebImgId: {
          XR_WORK: {
            width: 1068,
            height: 1080
          }
        },
        productSceneEnWebImgId: {
          XR_WORK: {
            width: 1068,
            height: 1080
          }
        },
        productSceneZhAndroidImgId: {
          XR_WORK: {
            width: 1280,
            height: 720
          }
        },
        productSceneEnAndroidImgId: {
          XR_WORK: {
            width: 1280,
            height: 720
          }
        },
        loginBgZhImgId: {
          XR_WORK: {
            width: 620,
            height: 566
          }
        },
        loginBgEnImgId: {
          XR_WORK: {
            width: 620,
            height: 566
          }
        }
      }
    }
  },
  computed:{
    ...mapGetters([
      'company'
    ]),
  },
  mounted() {
    this.$ajax({
      url: this.$api.findCompanyProductVersion,
      data: {
        companyUid: this.company.uid
      }
    }).then(res => {
      this.formData.xrWorkVersionConfigs = res.filter(item => item.versionType !== 'NORMAL').map(item => {
        return {
          xrWorkType: item.versionType,
          loginBgZhImgId: null,
          loginBgEnImgId: null,
          versionZhName: null,
          versionEnName: null,
        }
      })
      this.setDefaultValue()
    })
  },
  methods: {
    getSrc(data) {
      return data.fileHost + '/' + data.objectName
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          Object.keys(this.formData).forEach(key => {
            if(key.indexOf('ImgId') > -1 && this.tableRow[key.replace('Id', '')]) {
              this.formData[key] = this.tableRow[key.replace('Id', '')]
            } else if(key.indexOf('ImgId') === -1) {
              if(key === 'companyUid') {
                this.formData.companyUid = this.tableRow.companyData
              } else if(key === 'xrWorkVersionConfigs') {
                console.log('xrWorkVersionConfigs')
                this.formData.xrWorkVersionConfigs.map(item => {
                  const xrConfig = this.tableRow.xrWorkVersionConfigs.filter(item1 => item1.xrWorkType === item.xrWorkType)[0]
                  item.loginBgZhImgId = xrConfig.loginBgZhImg
                  item.loginBgEnImgId = xrConfig.loginBgEnImg
                  item.versionZhName = xrConfig.versionZhName
                  item.versionEnName = xrConfig.versionEnName
                  item.xrWorkType = item.xrWorkType
                })
              } else {
                this.formData[key] = this.tableRow[key]
              }
            }
          })
          if(! this.formData.connectUsType) {
            this.formData.connectUsType = 'TEXT'
          }
        } else {
          this.formData.connectUsType = 'TEXT'
        }
        if (this.formData.connectUsType === 'TEXT') {
          this.config[12].hidden = false
          this.config[13].hidden = false
          this.config[14].hidden = false
          this.config[15].hidden = false
          this.config[16].hidden = false
          this.config[17].hidden = true
        } else if(this.formData.connectUsType === 'URL') {
          this.config[12].hidden = true
          this.config[13].hidden = true
          this.config[14].hidden = true
          this.config[15].hidden = true
          this.config[16].hidden = true
          this.config[17].hidden = false
        }
      })
    },
    confirm() {
      const formData = this.$util.copyData(this.formData)
      console.log('aaaa', formData)
      Object.keys(formData).forEach(key => {
        if(key.indexOf('ImgId') > -1 && formData[key]) {
          formData[key] = formData[key].id
        }
        if(key === 'xrWorkVersionConfigs') {
          formData.xrWorkVersionConfigs = formData.xrWorkVersionConfigs.map(item => {
            if(item.loginBgZhImgId) {
              item.loginBgZhImgId = item.loginBgZhImgId.id
            }
            if(item.loginBgEnImgId) {
              item.loginBgEnImgId = item.loginBgEnImgId.id
            }
            return item
          })
        }
      })
      formData.companyUid = formData.companyUid.uid
      console.log('bbbb', formData)
      if (this.cmd === 'add') {
        this.$ajax({
          url: this.$api.oemConfigAdd,
          data: {
            type: this.$props.productType,
            ...formData,
          },
        }).then(res => {
          this.$emit('closePage', 'confirm')
        })
      } else if(this.cmd === 'update') {
        this.$ajax({
          url: this.$api.oemConfigChange,
          data: {
            type: this.$props.productType,
            id: this.tableRow.id,
            ...formData,
          },
        }).then(res => {
          this.$emit('closePage', 'confirm')
        })
      }
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      this.dialogTitle = this.$t('companyOemManage.targetCompany')
      this.dialogUrl =  this.$api.companyFind
      this.dialogDefaultParams = {
        productType : this.$util.getLocalStorage('productType')
      }
      this.dialogColumns = [
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
        },
        // lang:企业名称
        {
          label: 'UID',
          prop: 'uid',
        },
        // lang:行业
        {
          label: this.$t('companyManage.industry'),
          prop: 'industry',
          callback(row) {
            return row && row.industry && row.industry.value
          },
        },
        // lang:地址
        {
          label: this.$t('companyManage.address'),
          prop: 'address',
          callback(row) {
            return row && row.address && row.address.value
          },
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang:邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
        },
      ]
      this.dialogConfig = [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('companyManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 11,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ]
      this.showDialogTableList = true
    },
    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.dialogTableList.searchList(dialogFormData)
    },
    clickUpload(prop, xrWorkType = null) {
      this.currentUploadProp = prop
      this.currentUploadXrWorkType = xrWorkType
      this.$refs.uploadfile.click()
    },
    // 文件上传
    uploadFile() {
      const formData = new FormData()
      formData.append('fileFrom', 'WEB_ADMIN_SIGNATURE')
      formData.append('file', this.$refs.uploadfile.files[0])
      const reader = new FileReader()
      reader.readAsDataURL(this.$refs.uploadfile.files[0])
      reader.onload = () => {
        const img = new Image()
        img.src = reader.result
        img.onload = () => {
          console.log('img', img.width, img.height)
          const aspectRatio = Math.floor(img.width / img.height)
          const uploadAspectRatio = Math.floor(this.aspectRatioMap[this.currentUploadProp][this.productType].width / this.aspectRatioMap[this.currentUploadProp][this.productType].height)
          console.log(this.aspectRatioMap[this.currentUploadProp][this.productType].width, this.aspectRatioMap[this.currentUploadProp][this.productType].height)
          console.log(aspectRatio, uploadAspectRatio)
          if(Math.abs(aspectRatio - uploadAspectRatio) > 0.2) {
            this.$refs.uploadfile.value = ''
            return showAlert({
              content: this.$t('companyOemManage.resolutionNotUpToStandard'),
            })
          }
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.coreFileupload,
            data: formData,
          }).then(res => {
            console.log(res)
            if (this.currentUploadXrWorkType) {
              this.formData.xrWorkVersionConfigs.map(item => {
                if (item.xrWorkType === this.currentUploadXrWorkType) {
                  item[this.currentUploadProp] = res
                }
              })
            } else {
              this.formData[this.currentUploadProp] = res
            }
            // this.fileId = res.id
            // this.formData.coverImgId = res.id
            // this.fileSrc = res.fileHost + '/' + res.objectName
            this.$refs.uploadfile.value = ''
          })
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/theme.scss';
.xrsop-version-configs {
  &__item {
    background: #f5f7fa;
    margin-bottom: 20px;
    padding: 20px;
    .version-name {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
.upload {
  box-sizing: border-box;
  &--no-img {
    width: 80px;
    height: 80px;
    font-size: 20px;
    line-height: 80px;
    text-align: center;
    border: 1px dashed #cccccc;
    cursor: pointer;
    border-radius: 4px;
    &:hover{
      border: 1px dashed blue;
      color: blue;
    }
  }

  &--has-img {
    width: 80px;
    height: 80px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #cccccc;
    object-fit: contain;
    background: black;
  }
}
</style>
